.sidebar {
  background: linear-gradient(
    102.49deg,
    rgba(0, 195, 179, 1) -20%,
    rgba(110, 2, 137, 1) 90%
  );
  height: 100vh;
  position: fixed;
  width: 25%;
}

.side-logo {
  margin: auto;
  padding-top: 43px;
}

.navigation ul {
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin-bottom: 20px;
}

.side-btn {
  width: 200px;
}

.btn-image {
  align-self: center;
  margin-right: 15px;
}
.navigation button {
  background-color: #02bfb23f;
  border: none;
  text-decoration: none;
}

.active .side-btn {
  background-color: #02bfb2;
}

.power-sec {
  margin: auto;
}

.power {
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.1741025596857071px;
  text-align: left;
}

.side-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.support {
  background-color: #00000044;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 200px;
  display: inline-flex;
}
.border {
  border-top: 20px;
}

.social-contain {
  justify-content: space-between;
}

.side-social {
  margin: 5px;
}

/* .aside {
  overflow-y: hidden ;
  position: fixed;
} */
/* Main Page */
.only-scroll {
  overflow: auto;
}

.bridge-head {
  font-size: 48px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0px;
  text-align: left;
}

.bridge-subhead {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.22384615242481232px;
  text-align: left;
}

.network-head {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.3499999940395355px;
  text-align: left;
  color: #898989;
}

.networks {
  align-items: self-end;
}

.dash-table {
  min-height: 567px;
  background: #f0f0f0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.226754);
  border-radius: 24px;
  position: relative;
}

@media screen and (max-width: 650px) {
  .dash-table {
    min-height: 567px;
    position: relative;
    background: none;
    box-shadow: none;
  }
}

.top-green {
  background-color: #02bfb2;
  height: 24px;
  border-radius: 20px 20px 0px 0px;
}

.total-card {
  border-radius: 20px;
}

.total-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.1741025596857071px;
}

.total-fig {
  font-size: 31px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.3855128288269043px;
}

@media screen and (max-width: 650px) {
  .total-fig {
    font-size: 26px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.3233333230018616px;
    text-align: left;
    align-self: baseline;
  }

  .arrow-cover {
    background: rgb(255, 255, 255) !important;
    width: 70px;
    height: 70px;
    margin: auto;
    border-radius: 50%;
    position: absolute;
    top: -5%;
    left: 45%;
    transform: translate(-50%, -50%);
  }

  .from-card {
    background: rgba(216, 216, 216, 0.39);
    mix-blend-mode: normal;
    border-radius: 0px !important;
    align-items: last baseline;
    padding-bottom: 50px !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1741025596857071px;
    text-align: left;
  }

  .to-card {
    background: rgba(2, 191, 178, 0.16);
    mix-blend-mode: normal;
    border-radius: 0px !important;
    align-items: last baseline;
    padding-top: 50px !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1741025596857071px;
    text-align: left;
  }

  .transaction {
    background: rgba(2, 191, 178, 0.16);
  }

  .bridge-btn {
    width: fit-content;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.260869562625885px;
    text-align: left !important;
  }

  .bridge-btns {
    float: left !important;
    display: flex;
    justify-content: flex-start !important;
  }
}

.from-card {
  background: rgba(216, 216, 216, 0.39);
  mix-blend-mode: normal;
  border-radius: 20px;
  align-items: last baseline;
}

.to-card {
  background: rgba(2, 191, 178, 0.16);
  mix-blend-mode: normal;
  border-radius: 20px;
  align-items: last baseline;
}

.arrow-cover {
  background: rgba(240, 240, 240, 1);
  width: 70px;
  height: 70px;
  margin: auto;
  border-radius: 50%;
  position: absolute;
  top: -5%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.arrow-back {
  background: rgba(2, 191, 178, 1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  margin-top: 10px;
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txns-container {
}

.txns {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.3499999940395355px;
  text-align: left;
  color: #898989;
  justify-self: first baseline;
}

.txns-fee {
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.42500001192092896px;
  text-align: left;
  color: #494949;
}

.bridge-btns {
  float: right;
  display: flex;
  justify-content: flex-end;
}

.bridge-btn {
  width: fit-content;
}
