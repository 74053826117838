.dao-hero {
  background: linear-gradient(63.54deg, #00c3b3 16.59%, #6e0289 88.13%);
}

.france-dao-hero {
  background: linear-gradient(63.54deg, #002654 16.59%, #ed2939 88.13%);
}

.eboyin-dao-hero {
  background: linear-gradient(63.54deg, #008000 16.59%, #964b00 88.13%);
}

.dao-hero-heading {
  font-size: 67px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0px;
}

@media screen and (max-width: 900px) {
  .dao-hero-heading {
    font-size: 45px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.4115043878555298px;
    text-align: left;
  }
}

.dao-card {
  background: #f0f0f0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.226754);
  border-radius: 24px;
}

@media screen and (min-width: 900px) {
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dao-hero-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.3478260934352875px;
  text-align: right;
}

.dao-card button {
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .dao-hero-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.3478260934352875px;
    text-align: center;
    margin-top: 20px;
  }
}

.sec-two-head {
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.3979487121105194px;
  text-align: left;
  color: #212121;
}

.sec-two-sub {
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.3482051193714142px;
  text-align: left;
}

.vote-sec {
  background: #f0f0f0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.226754);
  border-radius: 24px;
}

.tape {
  border-radius: 24px 24px 0px 0px;
}

.vote-card-head {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.44999998807907104px;
  text-align: left;
}

.vote-card-sub {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.375px;
  text-align: left;
}

.treasury {
  display: flex;
  justify-content: space-between;
}

.treasury p {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.3730769157409668px;
  text-align: left;
}
.treasury img {
  mix-blend-mode: normal;
  opacity: 0.63;
  height: 34px;
  width: auto;
  align-self: center;
}

.treasury-bal p {
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.3478260934352875px;
  text-align: left;
}

.treasury-bal span {
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.3478260934352875px;
  text-align: left;
}

.token {
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.260869562625885px;
  text-align: left;
  justify-content: space-between;
  color: #808080;
}

.previous {
  font-size: 21px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.26115384697914124px;
  text-align: left;
  color: #595959;
}

.more-proposal {
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.375px;
  text-align: right;
}

.about-sec {
  background: #f5f5f5;
}
.about {
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.3979487121105194px;
  text-align: left;
  color: #212121;
}

.about-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.375px;
  text-align: left;
}

.how {
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.3979487121105194px;
  text-align: left;
}

.how-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.32499998807907104px;
  text-align: left;
}

.vote {
  font-size: 20px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.375px;
  text-align: center;
}

.vote p {
  margin-right: 10px;
}

.vote-icon {
  border-radius: 10px;
  color: #f0f0f0;
  font-size: 20px !important;
  margin-left: 0;
  float: left !important;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.75);
}

.asa {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.375px;
  text-align: right;
  color: #151515;
  mix-blend-mode: normal;
  opacity: 0.45;
}

.dao-icon {
  align-self: center !important;
}

.dao-name {
  align-self: center;
}

@media screen and (max-width: 430px) {
  .dao-icon {
    width: 20px;
    align-self: center;
  }

  .dao-name {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .dao-icon {
    width: 17px;
    align-self: center;
  }

  .dao-name {
    font-size: 10px;
  }
}

.dao-btn {
  height: 50px;
}

.forum-sec {
  background: #f0f0f0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.226754);
  border-radius: 24px;
  max-height: 400px;
}

.forum-inner {
  background: #e6e4e4;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.226754);
  border-radius: 15px;
  margin-bottom: 15px;
}

.proposer {
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #570c0c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* CSS for the tooltip content */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}

/* Show the tooltip when hovering over the element */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  margin-left: 20px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  border: 20px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
